<template>
    <teleport :to="`#POPUP_AREA${ layer == 0 ? '' : `_LAYER_${layer}` }`">
        <section class="overlayElement flex flex-col justify-center" :class="{
            'opacity-0 pointer-events-none' : !openned,
            'pointer-events-auto' : openned
        }">
            <transition name="fadeY">
                <div v-show="openned" v-if="openned || !deleteOnHide" class="flex flex-1 flex-col items-center justify-center"  @mousedown.self="preventClose ? null : close() ">
                    <slot></slot>
                </div>
            </transition>
        </section>
    </teleport>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const emit = defineEmits(['close', 'open']);

type Props = {
    deleteOnHide?: boolean,
    preventClose?: boolean,
    layer?: number
}

const props = withDefaults(defineProps<Props>(), {
    deleteOnHide: false,
    preventClose: false,
    layer: 0,
});

const openned = ref(false);

function close() {
    emit("close");
    openned.value = false;
}

function open() {
    emit("open");
    openned.value = true;
}

defineExpose({
    close,
    open,
})


</script>

<style>
.overlayElement{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 70;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
    transition: opacity 0.5s, transform 0.5s;
}

.overlayElementChild {
    transform: translateY(0rem);
    transition: opacity 0.5s, transform 0.5s;
}

.overlayCloseChild{
    transform: translateY(2rem);
}
/* .overlayClose > * {
    transform: translateY(0rem);
} */

</style>